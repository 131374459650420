<template>
  <div class="invoice-control">
    <div class="invoice-control-top">
      <div class="top-content">
        <div class="top-left">
          <div>可开发票额</div>
          <div>￥{{ invoicing }}</div>
        </div>
        <div class="top-right">
          <el-button type="primary" @click="applyInvoice">申请发票</el-button>
          <el-button type="primary" @click="manageInvoiceTitle">管理发票抬头</el-button>
        </div>
      </div>
      <div class="top-point">
        <div style="color:red">发票邮寄费用由您本人承担，平台将选择可以支持到付的快递为您邮寄发票。为保证您能收到发票，申请发票的个人信息请务必填写真实信息。由于您本人填写错误而导致收不到发票，由您本人承担后果。</div>
        发票金额可以是多次充值金额之和，建议您积累到一定金额后一并申请，企业增值税专用发票会在申请后5-7个工作日开具，
        若您在超过15个工作日仍未收到发票，请主动与我们联系，个人和企业普通发票会在申请后3-5个工作日开具，若您在超过10个工作日仍未收到发票，
        请与我们联系。
      </div>
    </div>
    <el-divider />
    <div class="invoice-control-content">
      <el-table
        v-loading="invoiceLoading"
        :data="invoiceControlData"
        stripe
        style="width: 100%; font-size: 14px;"
        @sort-change="onSortChange"
        @filter-change="filterChange"
      >
        <el-table-column prop="id" label="发票ID" sortable />
        <el-table-column prop="money" label="金额" sortable>
          <template #default="scope">
            <span>
              {{ getMoney(scope.row.money) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="类型"
          sortable
        >
          <template #default="scope">
            {{ scope.row.type === 'NORMAL' ? '普通发票' : '增值税专用发票' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="invoicePaper"
          label="发票载体"
          sortable
        >
          <template #default="scope">
            {{ scope.row.invoicePaper === 'PAPER' ? '纸质版' : '电子版' }}
          </template>
        </el-table-column>
        <el-table-column prop="companyName" label="抬头" />
        <el-table-column prop="registeredAddress" label="地址" show-overflow-tooltip />
        <el-table-column
          prop="states"
          label="状态"
        >
          <template #default="scope">
            <span v-if="scope.row.state === '已撤销' || scope.row.state === '已拒绝'" class="error">开票失败</span>
            <span v-else-if="scope.row.state === '已审批' || scope.row.state === '已处理'" class="success">开票成功</span>
            <span v-else class="running">开票中</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="提交时间" :formatter="formatTime" show-overflow-tooltip />
      </el-table>
      <!-- <div class="invoice-control-pagination">
        <el-pagination
          :total="total"
          :current-page="currentPage"
          background
          :page-size="pageSize"
          layout="total, sizes, slot, prev, next"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
          <span class="total-jump">
            <span class="current">{{ currentPage }} / {{ Math.ceil(total / pageSize) }}</span>
            <span class="jump-v-line" />
            <span class="jump-page">
              <span>跳至</span>
              <el-input v-model="jumpPage" size="small" style="width: 36px;" @keyup.enter.native="jumpTo" />
              <span>页</span>
            </span>
          </span>
        </el-pagination>
      </div> -->
      <div class="pagination">
        <div class="total">合计：{{ totalPage }}</div>
        <el-pagination
          v-model:currentPage="pageNum"
          :page-size="pageSize"
          :page-count="pagenumber"
          :page-sizes="pageSizes"
          layout="sizes"
          :total="totalPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
        <div class="jumper">
          <div>{{ pageNum }}/{{ pagenumber }}</div>
          <div class="jumper-line" />
          <el-pagination
            v-model:currentPage="pageNum"
            :page-size="pageSize"
            :page-count="pagenumber"
            :page-sizes="pageSizes"
            background
            layout="jumper"
            :total="totalPage"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
        <el-pagination
          v-model:currentPage="pageNum"
          :page-size="pageSize"
          :page-count="pagenumber"
          :page-sizes="pageSizes"
          background
          layout="prev, next"
          :total="totalPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <invoice-window :is-dialog="isDialog" :title="title" @closeInvoice="closeInvoice">
      <!-- 开具发票 -->
      <div v-if="isInvoice" v-loading="applyInvoiceLoading">
        <el-form ref="invoiceRefs" :model="applyBillForm" :rules="invoiceRules" label-width="150px">
          <el-form-item label="开具金额" prop="inputmoney">
            <el-input v-model="applyBillForm.inputmoney" placeholder="请输入开具金额" />
          </el-form-item>
          <el-form-item label="支付渠道" prop="payChannel">
            <el-select v-model="applyBillForm.payChannel" placeholder="请选择支付渠道">
              <el-option label="微信" :value="'微信'" />
              <el-option label="支付宝" :value="'支付宝'" />
              <el-option label="对公转账" :value="'对公转账'" />
            </el-select>
          </el-form-item>
          <el-form-item label="支付订单号" prop="payOrder">
            <el-input v-model="applyBillForm.payOrder" placeholder="请输入支付订单号" />
          </el-form-item>
          <el-form-item label="发票类目" prop="category">
            <el-select v-model="applyBillForm.category" placeholder="请选择发票类目">
              <el-option label="技术服务费" :value="0" />
              <el-option label="机时费" :value="1" />
              <el-option label="测试费" :value="2" />
              <el-option label="高性能计算测试服务" :value="3" />
              <el-option label="高性能计算机时服务费" :value="4" />
            </el-select>
          </el-form-item>
          <el-form-item label="开具类型" prop="aInvoiceTitle">
            <el-radio-group v-model="applyBillForm.aInvoiceTitle">
              <el-radio label="0">企业</el-radio>
              <el-radio label="1">个人</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="发票类型" prop="invoiceType">
            <el-radio-group v-model="applyBillForm.invoiceType">
              <el-radio label="0">增值税普通发票</el-radio>
              <el-radio label="1">增值税专用发票</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="applyBillForm.aInvoiceTitle === '0'">
            <el-form-item label="税务登记账号" prop="registrationNo">
              <el-input v-model="applyBillForm.registrationNo" placeholder="请输入税务登记账号" />
            </el-form-item>
            <el-form-item label="开户银行名称" prop="bankName">
              <el-input v-model="applyBillForm.bankName" placeholder="请输入开户银行名称" />
            </el-form-item>
            <el-form-item label="基本开户行账号" prop="bankAccount">
              <el-input v-model="applyBillForm.bankAccount" placeholder="请输入基本开户行账号" />
            </el-form-item>
            <el-form-item label="企业名称" prop="companyName">
              <el-input v-model="applyBillForm.companyName" placeholder="请输入企业名称" />
            </el-form-item>
            <el-form-item label="企业注册地址" prop="registeredAddress">
              <el-input v-model="applyBillForm.registeredAddress" placeholder="请输入企业注册地址" />
            </el-form-item>
            <el-form-item label="企业注册电话" prop="registeredPhone">
              <el-input v-model="applyBillForm.registeredPhone" placeholder="请输入企业注册电话" />
            </el-form-item>
          </div>
          <div v-if="applyBillForm.aInvoiceTitle === '1'">
            <el-form-item label="个人姓名" prop="personName">
              <el-input v-model="applyBillForm.personName" placeholder="请填写个人姓名" />
            </el-form-item>
            <el-form-item label="个人电话" prop="personPhone">
              <el-input v-model="applyBillForm.personPhone" placeholder="请填写个人有效电话" />
            </el-form-item>
          </div>
          <el-form-item label="发票载体" prop="invoicePaper">
            <el-radio-group v-model="applyBillForm.invoicePaper">
              <el-radio label="ELECTRONIC">电子版</el-radio>
              <el-radio label="PAPER">纸质版</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="applyBillForm.invoicePaper === 'ELECTRONIC'">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="applyBillForm.email" placeholder="请填写邮箱" />
            </el-form-item>
          </div>
          <div v-if="applyBillForm.invoicePaper === 'PAPER'">
            <el-form-item label="发票邮寄接收人姓名" prop="sendName">
              <el-input v-model="applyBillForm.sendName" placeholder="请填写发票邮寄接收人姓名" />
            </el-form-item>
            <el-form-item label="发票邮寄接收人电话" prop="sendPhone">
              <el-input v-model="applyBillForm.sendPhone" placeholder="请填写发票邮寄接收人电话" />
            </el-form-item>
            <el-form-item label="发票邮寄接收人地址" prop="sendAddress">
              <el-input v-model="applyBillForm.sendAddress" placeholder="请填写发票邮寄接收地址" />
            </el-form-item>
          </div>
          <div class="submit">
            <el-button type="primary" :loading="submitInvoiceLoading" @click="submitApplyBillForm">提交</el-button>
            <el-button type="default" @click="closeInvoice('申请发票')">取消</el-button>
          </div>
        </el-form>
      </div>
      <!-- 管理发票抬头 -->
      <div v-else v-loading="applyInvoiceLoading">
        <el-form ref="invoiceHeaderRefs" :model="invoiceHeaderFrom" :rules="invoiceHeaderRules" label-width="140px">
          <el-form-item label="开具类型" prop="aInvoiceTitle">
            <el-radio-group v-model="invoiceHeaderFrom.aInvoiceTitle">
              <el-radio label="0">企业</el-radio>
              <el-radio label="1">个人</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="invoiceHeaderFrom.aInvoiceTitle === '0'">
            <el-form-item label="税务登记账号" prop="registrationNo">
              <el-input v-model="invoiceHeaderFrom.registrationNo" placeholder="请输入税务登记账号" />
            </el-form-item>
            <el-form-item label="开户银行名称" prop="bankName">
              <el-input v-model="invoiceHeaderFrom.bankName" placeholder="请输入开户银行名称" />
            </el-form-item>
            <el-form-item label="基本开户行账号" prop="bankAccount">
              <el-input v-model="invoiceHeaderFrom.bankAccount" placeholder="请输入基本开户行账号" />
            </el-form-item>
            <el-form-item label="企业名称" prop="companyName">
              <el-input v-model="invoiceHeaderFrom.companyName" placeholder="请输入企业名称" />
            </el-form-item>
            <el-form-item label="企业注册地址" prop="registeredAddress">
              <el-input v-model="invoiceHeaderFrom.registeredAddress" placeholder="请输入企业注册地址" />
            </el-form-item>
            <el-form-item label="企业注册电话" prop="registeredPhone">
              <el-input v-model="invoiceHeaderFrom.registeredPhone" placeholder="请输入企业注册电话" />
            </el-form-item>
          </div>
          <div v-else>
            <el-form-item label="个人姓名" prop="personName">
              <el-input v-model="invoiceHeaderFrom.personName" placeholder="请填写个人姓名" />
            </el-form-item>
            <el-form-item label="个人电话" prop="personPhone">
              <el-input v-model="invoiceHeaderFrom.personPhone" placeholder="请填写个人有效电话" />
            </el-form-item>
          </div>
          <div class="submit">
            <el-button type="primary" :loading="submitInvoiceHeaderLoading" @click="editInvoiceRise">提交</el-button>
            <el-button type="default" @click="closeInvoice('发票抬头')">取消</el-button>
          </div>
        </el-form>
      </div>
    </invoice-window>
  </div>
</template>

<script>
import InvoiceWindow from '@/components/InvoiceWindow'
import { ElMessage } from 'element-plus'
import { formatTime } from '@/utils/formatTime'
import validate from '@/utils/baseValidate.js'
import {
  applyInvoice,
  getInvoicingLimit,
  manageInvoiceRiseHistory,
  editInvoiceHeader,
  queryInvoiceHistory
} from '@/api/ehpc'
import { nextTick } from 'vue'
export default {
  name: 'InvoiceControl',
  components: {
    InvoiceWindow
  },
  data() {
    const checkMoney = (rule, value, callback) => {
      // const reg = new RegExp('')
      const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/
      value = value || ''
      if (value === null || value === '') {
        callback(new Error('请输入金额'))
      } else if (value.length === 0 || !reg.test(value)) {
        callback(new Error('请输入正确的金额'))
      } else if (value < 0 || value > (this.invoiceQuota / 1000)) {
        callback(new Error('金额超出可开票范围'))
      } else {
        callback()
      }
    }
    const phoneRules = (rule, value, callback) => {
      const tel = /^0\d{2,3}-?\d{7,8}$/
      const phone = /^1[3456789]\d{9}$/
      value = value || ''
      if (value.length === 11) {
        if (phone.test(value)) {
          callback()
        }
      } else if (value.length === 13 && value.indexOf('-') !== -1) {
        if (tel.test(value)) {
          callback()
        }
      }
      callback(new Error('请输入正确的电话号码'))
    }
    return {
      invoiceControlData: [],
      pageSize: 10,
      pageNum: 1,
      pagenumber: 0,
      totalPage: 0,
      pageSizes: [5, 10, 20, 30],
      title: '',
      sortBy: '',
      orderBy: '',
      invoicingLimit: 0,
      isContent: false,
      // 申请发票
      applyBillForm: {
        inputmoney: null,
        aInvoiceTitle: '0', // 开具类型
        invoiceType: '0', // 发票类型
        account: '',
        registrationNo: '', // 税务登记账号
        bankName: '', // 银行名称
        bankAccount: '', // 基本开户行账号
        companyName: '', // 公司名称
        personName: '',
        registeredAddress: '', // 企业注册地址
        registeredPhone: '', // 企业注册电话
        personPhone: '',
        sendName: '',
        sendPhone: '',
        sendAddress: '',
        email: '',
        category: '',
        invoicePaper: 'ELECTRONIC', // 发票载体
        payChannel: '对公转账',
        payOrder: ''
      },
      invoiceRules: {
        inputmoney: [
          { required: true, validator: checkMoney, trigger: 'blur' }
        ],
        category: [
          { required: true, message: '请选择发票类目', trigger: 'change' }
        ],
        payOrder: [
          { required: true, message: '请输入支付订单号', trigger: 'blur' },
          { max: 200, message: '支付订单号长度不能超过200个字符', trigger: 'blur' }
        ],
        payChannel: [
          { required: true, message: '请选择支付渠道', trigger: 'change' }
        ],
        invoiceType: [
          { required: true, message: '请选择发票类型', trigger: 'change' }
        ],
        aInvoiceTitle: [
          { required: true, message: '请选择发票类型', trigger: 'change' }
        ],
        registrationNo: [
          { required: true, message: '请输入税务登记账号', trigger: 'blur' }
        ],
        bankName: [
          { required: true, message: '请输入开户银行', trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        personName: [
          { required: true, validator: validate.linkName, trigger: 'blur' }
        ],
        bankAccount: [
          { required: true, message: '请输入银行账号', trigger: 'blur' }
        ],
        registeredAddress: [
          { required: true, message: '请输入地址', trigger: 'blur' }
        ],
        registeredPhone: [
          { required: true, validator: phoneRules, trigger: 'blur' }
        ],
        invoicePaper: [
          { required: true, message: '请选择发票载体', trigger: 'change' }
        ],
        personPhone: [
          { required: true, validator: phoneRules, trigger: 'blur' }
        ],
        sendPhone: [
          { required: true, validator: phoneRules, trigger: 'blur' }
        ],
        sendName: [
          { required: true, validator: validate.linkName, trigger: 'blur' }
        ],
        sendAddress: [
          { required: true, message: '请输入详细的发票接收地址', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱', trigger: 'blur' }
        ]
      },
      // 管理发票抬头
      invoiceHeaderFrom: {
        aInvoiceTitle: '0', // 开具类型
        account: '',
        registrationNo: '', // 税务登记账号
        bankName: '', // 银行名称
        bankAccount: '', // 基本开户行账号
        companyName: '', // 公司名称/个人姓名
        personName: '',
        registeredAddress: '', // 企业注册地址
        registeredPhone: '', // 企业注册电话/个人电话
        personPhone: ''
      },
      invoiceHeaderRules: {
        aInvoiceTitle: [
          { required: true, message: '请选择发票类型', trigger: 'change' }
        ],
        registrationNo: [
          { required: true, message: '请输入税务登记账号', trigger: 'blur' }
        ],
        bankName: [
          { required: true, message: '请输入开户银行', trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        personName: [
          { required: true, message: '请输入个人姓名', trigger: 'blur' }
        ],
        bankAccount: [
          { required: true, message: '请输入银行账号', trigger: 'blur' }
        ],
        registeredAddress: [
          { required: true, message: '请输入地址', trigger: 'blur' }
        ],
        registeredPhone: [
          { required: true, validator: phoneRules, trigger: 'blur' }
        ],
        personPhone: [
          { required: true, validator: phoneRules, trigger: 'blur' }
        ]
      },
      isDialog: false,
      isInvoice: true,
      submitInvoiceLoading: false,
      submitInvoiceHeaderLoading: false,
      applyInvoiceLoading: false,
      invoiceLoading: false,
      invoiceCategory: ['技术服务费', '机时费', '测试费', '高性能计算测试服务', '高性能计算机时服务费']
    }
  },
  computed: {
    invoicing() {
      return (this.invoicingLimit / 1000).toFixed(3).replace(/\.?0+$/, '')
    }
  },
  mounted() {
    this.queryInvoicingLimit()
    this.utilGetInvoiceHistory()
    this.$emit('viewLoadingChange', false)
  },
  methods: {
    getMoney(money) {
      return (money / 1000).toFixed(3).replace(/\.?0+$/, '')
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.utilGetInvoiceHistory()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.utilGetInvoiceHistory()
    },
    formatTime({ createTime }) {
      return formatTime(`${createTime}`, '{0}-{1}-{2} {3}:{4}:{5}')
    },
    // 打开申请发票
    applyInvoice() {
      this.title = '申请发票'
      this.isDialog = true
      this.isInvoice = true
      this.manageInvoice()
    },
    // 打开管理抬头
    manageInvoiceTitle() {
      this.title = '管理发票抬头'
      this.isDialog = true
      this.isInvoice = false
      this.manageInvoice()
    },
    // 关闭弹窗
    closeInvoice(title) {
      this.isDialog = false
      this.clearForm()
      if (title === '申请发票') {
        this.$refs.invoiceRefs.clearValidate()
      } else {
        this.$refs.invoiceHeaderRefs.clearValidate()
      }
    },
    // 开具发票
    submitApplyBillForm() {
      // this.$refs.invoiceRefs.fields.filter(item => item.prop === 'email').pop()
      this.submitInvoiceLoading = true
      const {
        inputmoney,
        invoiceType,
        companyName,
        personPhone,
        registrationNo,
        bankName,
        bankAccount,
        registeredAddress,
        registeredPhone,
        personName,
        sendName,
        sendPhone,
        sendAddress,
        email,
        category,
        invoicePaper,
        payChannel,
        payOrder
      } = this.applyBillForm
      let data = {}
      if (this.applyBillForm.aInvoiceTitle === '0' && this.applyBillForm.invoicePaper === 'ELECTRONIC') { // 企业发票 电子版
        data = {
          inputmoney: inputmoney * 1000,
          payChannel,
          payOrder,
          invoiceSubject: this.invoiceCategory[category],
          type: invoiceType,
          companyName,
          registrationNo,
          bankName,
          bankAccount,
          registeredAddress,
          registeredPhone,
          email,
          invoicePaper
        }
      } else if (this.applyBillForm.aInvoiceTitle === '1' && this.applyBillForm.invoicePaper === 'ELECTRONIC') { // 个人发票电子版
        data = {
          inputmoney: inputmoney * 1000,
          payChannel,
          payOrder,
          invoiceSubject: this.invoiceCategory[category],
          type: invoiceType,
          companyName: personName,
          registeredPhone: personPhone,
          email,
          invoicePaper
        }// 企业普通发票纸质版
      } else if (this.applyBillForm.aInvoiceTitle === '0' && this.applyBillForm.invoicePaper === 'PAPER') { // 企业普通发票纸质版
        data = {
          inputmoney: inputmoney * 1000,
          payChannel,
          payOrder,
          invoiceSubject: this.invoiceCategory[category],
          type: invoiceType,
          companyName,
          registrationNo,
          bankName,
          bankAccount,
          registeredAddress,
          registeredPhone,
          sendName,
          sendPhone,
          sendAddress,
          invoicePaper
        }
      } else if (this.applyBillForm.aInvoiceTitle === '1' && this.applyBillForm.invoicePaper === 'PAPER') { // 个人普通发票纸质版
        data = {
          inputmoney: inputmoney * 1000,
          payChannel,
          payOrder,
          invoiceSubject: this.invoiceCategory[category],
          type: invoiceType,
          companyName: personName,
          registeredPhone: personPhone,
          sendName,
          sendPhone,
          sendAddress,
          invoicePaper
        }
      }
      this.$refs.invoiceRefs.validate((valid) => {
        for (const key in data) {
          if (data[key] === '') {
            this.submitInvoiceLoading = false
            return
          }
        }

        this.$nextTick(() => {
          applyInvoice(data).then(res => {
            if (res.data.meta.status === 200) {
              this.submitInvoiceLoading = false
              this.$refs.invoiceRefs.clearValidate()
              this.isDialog = false
              this.manageInvoice()
              this.utilGetInvoiceHistory()
              this.queryInvoicingLimit()
              ElMessage({
                type: 'success',
                message: '申请成功'
              })
            } else {
              setTimeout(() => {
                this.submitInvoiceLoading = false
                this.$refs.invoiceRefs.clearValidate()
                this.isDialog = false
              }, 350)
              this.manageInvoice()
              ElMessage({
                type: 'error',
                message: '操作失败'
              })
            }
          }).catch(() => {
            this.submitInvoiceLoading = false
            this.$refs.invoiceRefs.clearValidate()
            this.manageInvoice()
          })
        })
      })
    },
    // 查询开票额度
    queryInvoicingLimit() {
      getInvoicingLimit().then(res => {
        if (res.meta.status === 200) {
          this.invoicingLimit = res.data.toFixed(2)
        }
      })
    },
    // 修改设置抬头
    editInvoiceRise() {
      this.submitInvoiceHeaderLoading = true
      const {
        bankName,
        bankAccount,
        companyName,
        registeredAddress,
        registeredPhone,
        registrationNo,
        aInvoiceTitle,
        personName,
        personPhone
      } = this.invoiceHeaderFrom
      const data = {}
      // 企业
      if (aInvoiceTitle === '0') {
        data.bankAccount = bankAccount
        data.bankName = bankName
        data.companyName = companyName
        data.registeredAddress = registeredAddress
        data.registeredPhone = registeredPhone
        data.registrationNo = registrationNo
      } else if (aInvoiceTitle === '1') {
        data.bankAccount = null
        data.bankName = null
        data.companyName = personName
        data.registeredAddress = null
        data.registeredPhone = personPhone
        data.registrationNo = null
      }
      this.$refs.invoiceHeaderRefs.validate((valid) => {
        for (const key in data) {
          if (data[key] === '') {
            this.submitInvoiceHeaderLoading = false
            return
          }
        }
        editInvoiceHeader(data).then(res => {
          if (res.meta.status) {
            this.submitInvoiceHeaderLoading = false
            this.isDialog = false
            this.manageInvoice()
            return ElMessage({
              message: '提交成功',
              type: 'success'
            })
          }
        }).catch(() => {
          this.$refs.invoiceHeaderRefs.clearValidate()
          this.submitInvoiceHeaderLoading = false
        })
      })
    },
    // 管理发票抬头
    manageInvoice() {
      this.applyInvoiceLoading = true
      manageInvoiceRiseHistory().then(res => {
        if (res.meta.status === 200) {
          this.applyInvoiceLoading = false
          const { data } = res.data
          if (data.length === 0) return
          // 管理发票抬头
          if (!this.isInvoice) {
            this.clearInvoiceHeader()
            if (data.length !== 0 && data[0].registrationNo == null) {
              const perData = data[0]
              this.personOrCompanyInvoice('1', perData)
            } else {
              this.personOrCompanyInvoice('0', data[0])
            }
            if (data[1] && data[1].registrationNo == null) {
              const perData = data[1]
              this.personOrCompanyInvoice('1', perData)
            } else {
              const perData = data[1]
              this.personOrCompanyInvoice('0', perData)
            }
          } else {
            this.clearForm()
            if (data.length !== 0 && data[0].registrationNo == null) {
              const perData = data[0]
              this.applyPersonOrCompanyInvoice('1', perData)
            } else {
              this.applyPersonOrCompanyInvoice('0', data[0])
            }
            if (data[1] && data[1].registrationNo == null) {
              const perData = data[1]
              this.applyPersonOrCompanyInvoice('1', perData)
            } else {
              const perData = data[1]
              this.applyPersonOrCompanyInvoice('0', perData)
            }
          }
        }
      })
    },
    // 清空申请发票表单
    clearForm() {
      this.applyBillForm.inputmoney = null
      this.applyBillForm.category = ''
      this.applyBillForm.payChannel = '对公转账'
      this.applyBillForm.payOrder = ''
      this.applyBillForm.invoicePaper = 'ELECTRONIC'
      this.applyBillForm.companyName = ''
      this.applyBillForm.registrationNo = ''
      this.applyBillForm.bankName = ''
      this.applyBillForm.personName = ''
      this.applyBillForm.personPhone = ''
      this.applyBillForm.bankAccount = ''
      this.applyBillForm.registeredAddress = ''
      this.applyBillForm.registeredPhone = ''
      this.applyBillForm.sendName = ''
      this.applyBillForm.sendPhone = ''
      this.applyBillForm.sendAddress = ''
      this.applyBillForm.email = ''
      if (this.$refs.invoiceRefs) {
        this.$nextTick(() => {
          this.$refs.invoiceRefs.clearValidate()
        })
      }
    },
    // 管理抬头
    personOrCompanyInvoice(type, data) {
      this.invoiceHeaderFrom.applyInvoice = '0'
      if (type === '0') {
        this.invoiceHeaderFrom.companyName = data.companyName
        this.invoiceHeaderFrom.registeredPhone = data.registeredPhone
        this.invoiceHeaderFrom.registrationNo = data.registrationNo
        this.invoiceHeaderFrom.bankName = data.bankName
        this.invoiceHeaderFrom.bankAccount = data.bankAccount
        this.invoiceHeaderFrom.registeredAddress = data.registeredAddress
      } else {
        this.invoiceHeaderFrom.personName = data.companyName
        this.invoiceHeaderFrom.personPhone = data.registeredPhone
      }
    },
    // 申请发票
    applyPersonOrCompanyInvoice(type, data) {
      this.applyBillForm.applyInvoice = '0'
      if (type === '0') {
        this.applyBillForm.companyName = data.companyName
        this.applyBillForm.registeredPhone = data.registeredPhone
        this.applyBillForm.registrationNo = data.registrationNo
        this.applyBillForm.bankName = data.bankName
        this.applyBillForm.bankAccount = data.bankAccount
        this.applyBillForm.registeredAddress = data.registeredAddress
      } else {
        this.applyBillForm.personName = data.companyName
        this.applyBillForm.personPhone = data.registeredPhone
      }
    },
    // 清空发票抬头表单
    clearInvoiceHeader() {
      this.invoiceHeaderFrom.aInvoiceTitle = '0'
      this.invoiceHeaderFrom.registrationNo = ''
      this.invoiceHeaderFrom.companyName = ''
      this.invoiceHeaderFrom.backName = ''
      this.invoiceHeaderFrom.bankAccount = ''
      this.invoiceHeaderFrom.registeredAddress = ''
      this.invoiceHeaderFrom.registeredPhone = ''
      this.invoiceHeaderFrom.personName = ''
      this.invoiceHeaderFrom.personPhone = ''
    },
    onSortChange({ column, prop, order }) {
      const data = {
        sortBy: order ? prop : '',
        orderBy: order === 'ascending' ? 'asc' : (order === 'descending' ? 'desc' : '')
      }
      this.sortBy = data.sortBy
      this.orderBy = data.orderBy
      this.utilGetInvoiceHistory()
    },
    // 获取发票记录
    getInvoiceHistory(sortBy, orderBy) {
      this.invoiceLoading = true
      this.isContent = true
      queryInvoiceHistory(this.pageNum, this.pageSize, sortBy, orderBy).then(res => {
        if (res.meta.status === 200) {
          const data = res.data
          if (data.data === null) {
            this.invoiceLoading = false
          } else {
            this.invoiceControlData = data.data.content
            // this.total = data.data.totalelements
            // this.pageNum = data.data.totalpages
            this.pagenumber = data.data.totalpages
            this.totalPage = data.data.totalelements
            this.invoiceLoading = false
          }
        }
      })
    },
    utilGetInvoiceHistory() {
      this.getInvoiceHistory(this.sortBy, this.orderBy, this.typeList, this.statesList)
    },
    // 跳转
    jumpTo() {
      if (Number(this.jumpPage) < 1) {
        this.currentPage = 1
        this.jumpPage = '1'
      } else if (Number(this.jumpPage) > Math.ceil(this.total / this.pageSize)) {
        this.currentPage = Math.ceil(this.total / this.pageSize)
        this.jumpPage = String(Math.ceil(this.total / this.pageSize))
      } else {
        this.currentPage = Number(this.jumpPage)
      }
      this.utilGetInvoiceHistory()
    },
    filterChange(filtersObj) {
      const typefilters = filtersObj.type
      if (typefilters) {
        this.typeList = typefilters.join(',')
      }
      if (filtersObj.states) {
        this.statesList = filtersObj.states.join(',')
      }
      // 调接口帅选数据
      this.currentPage = 1
      this.utilGetInvoiceHistory()
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
  height: 5px !important;
}
.submit {
  width: 100%;
  margin: 40px 0 0 0 ;
  display: flex;
  flex-direction: row-reverse;
  /deep/ .el-button:nth-child(2) {
    color: #4E5969 !important;
    margin-right: 10px;
  }
}
/deep/ .el-button {
  font-size: 14px !important;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400 !important;
  color: #FFFFFF;
}
/deep/ .el-divider--horizontal {
  margin: 15px 0 15px 0 !important;
}
// /deep/ .btn-prev,.btn-next {
// }
.invoice-control {
  // padding: 24px 64px 24px 44px;
  height: 100%;
  width: 100%;
  // overflow: auto;
  .invoice-control-top {
    min-width: 690px;
    padding-right: 10px;
    .top-content {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      .top-left {
        div:nth-child(1) {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
        }
        div:nth-child(2) {
          font-size: 26px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
        }
      }
    }
    .top-point {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #909399;
      // height: 15;
      // max-height: 56px;
      // overflow-y: scroll;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }
  }
  .invoice-control-content {
    min-width: 690px;
    padding-right: 10px;
    .running {
      color: #165DFF;
    }
    .success {
      color: #00A870;
    }
    .error {
      color: #FF3A3A;
    }
    .invoice-control-pagination {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      margin-top: 20px;
    }
  }
}

.total-jump {
  border: 1px solid #E7E7E7;
  padding: 0 5px 0 5px;
  font-weight: 400;
  .jump-v-line {
    height: 70%;
    width: 1px !important;
    min-width: 10px;
    margin-top: 5px;
    border-left: 1px solid #E7E7E7;
  }
  .jump-page {
    margin: 0;
  }
  /deep/ .el-input--small {
    width: 40px !important;
    margin-right: 5px;
    .el-input__inner {
      height: 100%;
      width: 40px !important;
      border: none !important;
      outline: none !important;
    }
  }
}
</style>
