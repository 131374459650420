<template>
  <div v-if="isDialog" class="invoice-window">
    <div class="invoice-content">
      <div class="invoice-header">
        <div class="header-title">{{ title }}</div>
        <div @click="clickDialog">
          <span style="cursor: pointer;">
            <img src="@/assets/icon/关闭.png" alt="">
          </span>
        </div>
      </div>
      <div class="invoice-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvoiceWindow',
  props: ['isDialog', 'title'],
  methods: {
    clickDialog() {
      this.$emit('closeInvoice', this.title)
    }
  }
}
</script>

<style scope lang="less">
.invoice-window {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  .invoice-content {
    width: 626px;
    background: #FFFFFF;
    border-radius: 20px;
    .invoice-header {
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom: 1px solid #F5F5F5;
      display: flex;
      background: #F5F5F5;
      height: 60px;
      align-items: center;
      justify-content: space-between;
      padding: 0 36px 0 44px;
      .header-title {
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #595F67;
      }
    }
    .invoice-content {
      padding: 36px;
      height: 600px;
      overflow: auto;
    }
  }
}
</style>
